import React from 'react';

import dynamic from 'next/dynamic';
import { Inter } from 'next/font/google';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import useMediaQuery from '../../../hooks/useMediaQuery';
import { EVENT_ATTRIBUTE, EVENT_NAME, useMoengageEventTracker } from '../../../hooks/useMoengage';

import { javaApi } from '../../../utils/AxiosInstance';

import styles from './CourseSlider.module.scss';

const inter = Inter({ subsets: ['latin'] });

const AccordionComponent = dynamic(() => import('../../Accordion'));
const CategorySliderBar = dynamic(() => import('../../CategorySliderBar'));
const StoreCourseSlider = dynamic(() => import('../../Shop/CourseSlider'));

function SubCategoryWrapper({ categories, onChange }) {
  const prevRef = React.useRef();
  const nextRef = React.useRef();

  const [swiper, setSwiper] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState(categories?.[0]?.slug || null);

  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  React.useEffect(() => {
    if (typeof onChange === 'function' && selectedCategory) {
      onChange(selectedCategory);
    }
  }, [selectedCategory, onChange]);

  return (
    <div className={styles.subCategorySliderWrapper}>
      <Swiper
        className={styles.subCategorySlider}
        modules={[Navigation, FreeMode]}
        slidesPerView="auto"
        onSwiper={setSwiper}
        spaceBetween={18}
      >
        {categories.map((category) => (
          <SwiperSlide key={category.id} className={styles.sliderSlide}>
            <span
              className={classNames(styles.sliderItem, {
                [styles.active]: selectedCategory === category.slug,
              })}
              onClick={() => setSelectedCategory(category.slug)}
              data-testid="course-slider-item"
            >
              {category.title}
            </span>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className={styles.navigationWrapper}>
        <button type="button" ref={prevRef} className={styles.navPrevSlide} data-testid="course-slider-prev">
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button type="button" ref={nextRef} className={styles.navNextSlide} data-testid="course-slider-next">
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
}

function CategoryAccordion({ active, category, onOpen, handleOnProductClick }) {
  const trackMoengageEvent = useMoengageEventTracker();

  const [showPrice, setShowPrice] = React.useState(false);
  const [subCategory, setSubCategory] = React.useState(null);
  const [sections, setSections] = React.useState([]);

  // eslint-disable-next-line no-shadow
  const handleOnSubCategoryChange = React.useCallback((category) => {
    trackMoengageEvent(EVENT_NAME.HOMEPAGE_CONTAINER_SUBCATEGORY_CLICKED, { category });

    setSubCategory(category);
  }, []);

  const subCategories = React.useMemo(
    () =>
      sections
        .map((section) => ({
          id: section.id || section.slug,
          slug: section.slug,
          title: section.title || section.heading || '',
        }))
        .filter((cat) => cat.title.length),
    [sections],
  );

  const currentSection = React.useMemo(
    () => sections.find((section) => section.slug === subCategory),
    [sections, subCategory],
  );

  const handleOnOpen = React.useCallback(
    (key, options) => {
      if (typeof onOpen === 'function') onOpen(key, options);

      javaApi
        .get('store-ws/api/v1/store/homepage', {
          params: { menuSlug: category.slug },
        })
        .then((response) => {
          // eslint-disable-next-line no-shadow
          const { content, showPrice } = response.data.data || {};

          setSections(content.sections);
          setShowPrice(showPrice);
        });
    },
    [category.slug],
  );

  return (
    <AccordionComponent
      key={category.slug}
      title={category.title || category.heading}
      opened={active}
      options={{
        accordionContentWrapperClass: styles.accordionContentWrapperClass,
        onOpen: handleOnOpen,
      }}
      data-testid="course-slider-accordion"
    >
      <div
        onClick={(event) => event.stopPropagation()}
        style={{ padding: '15px 0px' }}
        data-testid="course-slider-content"
      >
        {subCategories.length > 0 && (
          <SubCategoryWrapper
            key={category.slug}
            categories={subCategories}
            onChange={handleOnSubCategoryChange}
            data-testid="course-slider-subcategory-wrapper"
          />
        )}

        {currentSection && (
          <StoreCourseSlider
            key={subCategory}
            products={currentSection.sectionList}
            theme={currentSection.theme}
            section={currentSection}
            showPrice={showPrice}
            onProductClick={handleOnProductClick}
          />
        )}
      </div>
    </AccordionComponent>
  );
}

function HomepageCourseSlider({ categories }) {
  const trackMoengageEvent = useMoengageEventTracker();
  const isMobile = useMediaQuery('(max-width:480px)');

  const [showPrice, setShowPrice] = React.useState(false);
  const [sections, setSections] = React.useState([]);

  const [category, setCategory] = React.useState(null);
  const [subCategory, setSubCategory] = React.useState(null);

  const subCategories = React.useMemo(
    () =>
      sections
        .map((section) => ({
          id: section.id,
          slug: section.slug,
          title: section.title || section.heading || '',
        }))
        .filter((cat) => cat.title.length),
    [sections],
  );

  // eslint-disable-next-line no-shadow
  const handleOnCategoryChange = React.useCallback((category) => {
    trackMoengageEvent(EVENT_NAME.HOMEPAGE_CONTAINER_CATEGORY_CLICKED, {
      category,
    });

    javaApi
      .get('store-ws/api/v1/store/homepage', {
        params: { menuSlug: category },
      })
      .then((response) => {
        // eslint-disable-next-line no-shadow
        const { content, showPrice } = response.data.data || {};

        setCategory(category);
        setSections(content.sections);
        setShowPrice(showPrice);
      });
  }, []);

  // eslint-disable-next-line no-shadow
  const handleOnSubCategoryChange = (category) => {
    trackMoengageEvent(EVENT_NAME.HOMEPAGE_CONTAINER_SUBCATEGORY_CLICKED, { category });

    setSubCategory(category);
  };

  const currentSection = React.useMemo(
    () => sections.find((section) => section.slug === subCategory),
    [sections, subCategory],
  );

  const handleOnProductClick = (product) => {
    if (product) {
      trackMoengageEvent(EVENT_NAME.HOMEPAGE_CONTAINER_PDP_CLICKED, {
        [EVENT_ATTRIBUTE.COURSE_ID]: product.courseId,
        [EVENT_ATTRIBUTE.COURSE_NAME]: product.title,
        [EVENT_ATTRIBUTE.COURSE_TYPE]: product.discountPrice > 0 ? 'paid' : 'free',
      });
    }
  };

  if (isMobile) {
    return (
      <section
        id="homepage-course-slider"
        className={classNames(styles.wrapper, styles.isMobile)}
        data-testid="course-slider"
      >
        {/* <div className={styles.wrapperTitle} > */}
        <div className={`${styles.wrapperTitle} ${inter.className}`}>Start Your Learning Journey Today</div>
        {categories?.map((cat, index) => (
          <CategoryAccordion
            key={cat.slug}
            category={cat}
            active={(category === null && index === 0) || category === cat.slug}
            onOpen={() => setCategory(cat.slug)}
            data-testid="course-slider-accordion"
            handleOnProductClick={handleOnProductClick}
          />
        ))}
      </section>
    );
  }

  return (
    <section id="homepage-course-slider" className={styles.wrapper}>
      {/* <div className={styles.wrapperTitle}> */}
      <div className={`${styles.wrapperTitle} ${inter.className}`}>Start Your Learning Journey Today</div>
      <CategorySliderBar
        categories={categories}
        position="top"
        onChange={handleOnCategoryChange}
        className={styles.categoriesSlider}
      />

      <SubCategoryWrapper key={category} categories={subCategories} onChange={handleOnSubCategoryChange} />

      {currentSection && (
        <StoreCourseSlider
          key={subCategory}
          products={currentSection.sectionList}
          theme={currentSection.theme}
          section={currentSection}
          showPrice={showPrice}
          onProductClick={handleOnProductClick}
          className={styles.storeCourseSliderWrapper}
          navigationWrapperClassName={styles.navigationWrapper}
        />
      )}
    </section>
  );
}

export default HomepageCourseSlider;
